import styles from "./footer.module.css";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <footer className={styles["footer"]}>
      <div className="container">
        <div className={`${styles["footer-container"]} glass`}>
          <div className={styles["grid-2"]}>
            <div>
              <p>
                <Link className={styles["link"]} to="/imprint">
                  Impressum
                </Link>
                <br />
                <Link className={styles["link"]} to="/privacy">
                  Datenschutz
                </Link>
              </p>
            </div>
            <div>
              <p>
                <Link className={styles["link"]} to="/contact">
                  Kontakt
                </Link>
                <br />
                Email:
                <span
                  className={styles["contact-e"]}
                  onClick={() =>
                    (window.location.href = "mailto:" + atob("YnVzaW5lc3NAY29ydmluLXJvc2UuZGU="))
                  }
                >
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 449.01 35.36">
                    <path
                      d="M7.06,13.52a8.71,8.71,0,0,1,4.43-1.12,9.21,9.21,0,0,1,4.79,1.26,8.9,8.9,0,0,1,3.35,3.55,11.13,11.13,0,0,1,1.22,5.31,11.37,11.37,0,0,1-1.22,5.32,9,9,0,0,1-8.14,4.94A8.81,8.81,0,0,1,7,31.66a7.62,7.62,0,0,1-2.9-2.84v3.63H.84V5.81H4.11V16.4A7.71,7.71,0,0,1,7.06,13.52Zm9.54,5.11a6.16,6.16,0,0,0-2.43-2.52,6.68,6.68,0,0,0-3.36-.87,6.54,6.54,0,0,0-3.33.89A6.41,6.41,0,0,0,5,18.68a8,8,0,0,0-.92,3.87A8,8,0,0,0,5,26.46,6.41,6.41,0,0,0,7.48,29a6.73,6.73,0,0,0,6.69,0,6.3,6.3,0,0,0,2.43-2.55,8.21,8.21,0,0,0,.9-3.94A8,8,0,0,0,16.6,18.63Z"
                      transform="translate(-0.84 -5.06)"
                    />
                    <path
                      d="M42.67,12.72V32.45H39.39V29.54a6.34,6.34,0,0,1-2.61,2.35,8,8,0,0,1-3.69.85,9,9,0,0,1-4.14-1,7,7,0,0,1-2.9-2.87A9.5,9.5,0,0,1,25,24.28V12.72h3.24V23.85a6.26,6.26,0,0,0,1.48,4.48,5.29,5.29,0,0,0,4,1.57,5.39,5.39,0,0,0,4.14-1.62,6.68,6.68,0,0,0,1.51-4.72V12.72Z"
                      transform="translate(-0.84 -5.06)"
                    />
                    <path
                      d="M51.13,32a6.81,6.81,0,0,1-2.85-2.16,5.75,5.75,0,0,1-1.15-3.18h3.39a3.3,3.3,0,0,0,1.38,2.41,5.32,5.32,0,0,0,3.26.93,4.74,4.74,0,0,0,3-.82,2.54,2.54,0,0,0,1.08-2.09A2.05,2.05,0,0,0,58,25.16a16.42,16.42,0,0,0-3.56-1.24,25.28,25.28,0,0,1-3.59-1.17A6.4,6.4,0,0,1,48.52,21a4.61,4.61,0,0,1-1-3.08,4.65,4.65,0,0,1,.9-2.77,6.09,6.09,0,0,1,2.55-2,9.27,9.27,0,0,1,3.78-.74,8.06,8.06,0,0,1,5.3,1.66,6,6,0,0,1,2.16,4.53H58.94a3.39,3.39,0,0,0-1.24-2.48,5.47,5.47,0,0,0-5.85-.18,2.32,2.32,0,0,0-1.05,2,2.16,2.16,0,0,0,.63,1.6,4.57,4.57,0,0,0,1.59,1,27.53,27.53,0,0,0,2.64.84,29.82,29.82,0,0,1,3.46,1.14,6.08,6.08,0,0,1,2.29,1.67,4.57,4.57,0,0,1,1,2.92A5,5,0,0,1,61.5,30,6,6,0,0,1,59,32a9.11,9.11,0,0,1-3.76.74A10.12,10.12,0,0,1,51.13,32Z"
                      transform="translate(-0.84 -5.06)"
                    />
                    <path
                      d="M67.11,8.87a2.16,2.16,0,0,1-.65-1.58,2.18,2.18,0,0,1,.65-1.59,2.16,2.16,0,0,1,1.59-.64,2.05,2.05,0,0,1,1.53.64,2.2,2.2,0,0,1,.63,1.59,2.18,2.18,0,0,1-.63,1.58,2.06,2.06,0,0,1-1.53.65A2.16,2.16,0,0,1,67.11,8.87Zm3.17,3.85V32.45H67V12.72Z"
                      transform="translate(-0.84 -5.06)"
                    />
                    <path
                      d="M91.3,14.54a8.38,8.38,0,0,1,2.24,6.28V32.45H90.3V21.29a6.37,6.37,0,0,0-1.48-4.52,5.3,5.3,0,0,0-4-1.56,5.39,5.39,0,0,0-4.12,1.62,6.61,6.61,0,0,0-1.53,4.71V32.45H75.86V12.72h3.28v2.81a6.45,6.45,0,0,1,2.64-2.34,8.26,8.26,0,0,1,3.69-.83A8,8,0,0,1,91.3,14.54Z"
                      transform="translate(-0.84 -5.06)"
                    />
                    <path
                      d="M116.79,23.81H101A6.19,6.19,0,0,0,107.43,30,6.07,6.07,0,0,0,111,29a5.27,5.27,0,0,0,2-2.64h3.52A8.43,8.43,0,0,1,113.34,31a9.59,9.59,0,0,1-5.91,1.79,10,10,0,0,1-5-1.26,8.89,8.89,0,0,1-3.47-3.59,11.08,11.08,0,0,1-1.26-5.38,11.19,11.19,0,0,1,1.22-5.36,8.53,8.53,0,0,1,3.44-3.55,10.18,10.18,0,0,1,5.09-1.24,10,10,0,0,1,5,1.22A8.5,8.5,0,0,1,115.73,17a9.93,9.93,0,0,1,1.17,4.84A19.69,19.69,0,0,1,116.79,23.81Zm-4.1-5.88a5.39,5.39,0,0,0-2.25-2.06,7.14,7.14,0,0,0-3.15-.7,6,6,0,0,0-4.23,1.59,6.43,6.43,0,0,0-2,4.39h12.46A6,6,0,0,0,112.69,17.93Z"
                      transform="translate(-0.84 -5.06)"
                    />
                    <path
                      d="M124.14,32a6.81,6.81,0,0,1-2.85-2.16,5.67,5.67,0,0,1-1.15-3.18h3.38a3.35,3.35,0,0,0,1.39,2.41,5.3,5.3,0,0,0,3.26.93,4.74,4.74,0,0,0,3-.82,2.54,2.54,0,0,0,1.08-2.09,2.07,2.07,0,0,0-1.15-1.93,16.35,16.35,0,0,0-3.57-1.24,25.1,25.1,0,0,1-3.58-1.17A6.4,6.4,0,0,1,121.53,21a4.56,4.56,0,0,1-1-3.08,4.59,4.59,0,0,1,.9-2.77,6,6,0,0,1,2.55-2,9.2,9.2,0,0,1,3.78-.74,8,8,0,0,1,5.29,1.66,5.91,5.91,0,0,1,2.16,4.53H132a3.39,3.39,0,0,0-1.24-2.48,5.47,5.47,0,0,0-5.85-.18,2.32,2.32,0,0,0-1,2,2.16,2.16,0,0,0,.63,1.6,4.57,4.57,0,0,0,1.59,1,26.63,26.63,0,0,0,2.64.84,29.82,29.82,0,0,1,3.46,1.14,6.05,6.05,0,0,1,2.28,1.67,4.46,4.46,0,0,1,1,2.92,5,5,0,0,1-.9,2.91A5.94,5.94,0,0,1,132,32a9.16,9.16,0,0,1-3.77.74A10.07,10.07,0,0,1,124.14,32Z"
                      transform="translate(-0.84 -5.06)"
                    />
                    <path
                      d="M142.93,32a6.81,6.81,0,0,1-2.85-2.16,5.75,5.75,0,0,1-1.15-3.18h3.38a3.35,3.35,0,0,0,1.39,2.41A5.3,5.3,0,0,0,147,30a4.74,4.74,0,0,0,2.95-.82A2.54,2.54,0,0,0,151,27.09a2.05,2.05,0,0,0-1.15-1.93,16.35,16.35,0,0,0-3.57-1.24,25.53,25.53,0,0,1-3.58-1.17A6.4,6.4,0,0,1,140.32,21a4.61,4.61,0,0,1-1-3.08,4.65,4.65,0,0,1,.9-2.77,6.09,6.09,0,0,1,2.55-2,9.27,9.27,0,0,1,3.78-.74,8,8,0,0,1,5.29,1.66A5.91,5.91,0,0,1,154,18.59h-3.27a3.39,3.39,0,0,0-1.24-2.48,5.47,5.47,0,0,0-5.85-.18,2.32,2.32,0,0,0-1.05,2,2.16,2.16,0,0,0,.63,1.6,4.57,4.57,0,0,0,1.59,1,26.63,26.63,0,0,0,2.64.84,29.82,29.82,0,0,1,3.46,1.14,6,6,0,0,1,2.28,1.67,4.46,4.46,0,0,1,1,2.92,5,5,0,0,1-.9,2.91,5.94,5.94,0,0,1-2.53,2,9.16,9.16,0,0,1-3.77.74A10.1,10.1,0,0,1,142.93,32Z"
                      transform="translate(-0.84 -5.06)"
                    />
                    <path
                      d="M186.58,23.63a11.68,11.68,0,0,1-2.11,6.71,6.08,6.08,0,0,1-5.08,2.46,4,4,0,0,1-4.22-2.95A6.51,6.51,0,0,1,173,32.07a4.8,4.8,0,0,1-2.52.73,4.2,4.2,0,0,1-3.78-2,8.94,8.94,0,0,1-.95-5.64,15.59,15.59,0,0,1,1.33-5.18,9.48,9.48,0,0,1,2.74-3.57,5.65,5.65,0,0,1,3.52-1.3,8,8,0,0,1,2.55.37,8.89,8.89,0,0,1,2.53,1.49l-.91,9.74q-.33,4.06,2.25,4.06a3.7,3.7,0,0,0,3.22-2,10.21,10.21,0,0,0,1.33-5.13q.34-6.84-2.54-10.43T173,9.62a11.39,11.39,0,0,0-6.28,1.75,11.83,11.83,0,0,0-4.32,5.05,19.46,19.46,0,0,0-1.78,7.63,18.05,18.05,0,0,0,1.06,7.57,10.08,10.08,0,0,0,3.86,4.9,11.16,11.16,0,0,0,6.26,1.7,13.31,13.31,0,0,0,3.18-.39,9.91,9.91,0,0,0,2.64-1l.65,2A9.63,9.63,0,0,1,175.41,40a15,15,0,0,1-3.65.44,14,14,0,0,1-7.56-2,12,12,0,0,1-4.73-5.7,19.94,19.94,0,0,1-1.37-8.69,21.22,21.22,0,0,1,2.17-8.69,14.58,14.58,0,0,1,5.29-6,13.85,13.85,0,0,1,7.54-2.1,13.7,13.7,0,0,1,7.5,2,11.77,11.77,0,0,1,4.66,5.67A20.41,20.41,0,0,1,186.58,23.63Zm-18,1.49A6.83,6.83,0,0,0,169,29a2.4,2.4,0,0,0,2.26,1.34,3,3,0,0,0,2-.9,6.59,6.59,0,0,0,1.61-2.56l0-.16.81-8.84a4.53,4.53,0,0,0-2.11-.51,4,4,0,0,0-3.41,2.09A12.75,12.75,0,0,0,168.55,25.12Z"
                      transform="translate(-0.84 -5.06)"
                    />
                    <path
                      d="M191.13,17.21a8.75,8.75,0,0,1,3.4-3.55,10.81,10.81,0,0,1,11,.5,8.33,8.33,0,0,1,3.11,4.9H205.1a5.06,5.06,0,0,0-2-2.84,6.05,6.05,0,0,0-3.62-1A5.8,5.8,0,0,0,195,17.1a7.89,7.89,0,0,0-1.73,5.45A8,8,0,0,0,195,28.06,5.77,5.77,0,0,0,199.52,30a6.14,6.14,0,0,0,3.6-1,5.17,5.17,0,0,0,2-2.88h3.52A8.65,8.65,0,0,1,205.46,31a9.53,9.53,0,0,1-5.94,1.82,9.79,9.79,0,0,1-5-1.26,8.74,8.74,0,0,1-3.4-3.57,11.27,11.27,0,0,1-1.23-5.4A11.08,11.08,0,0,1,191.13,17.21Z"
                      transform="translate(-0.84 -5.06)"
                    />
                    <path
                      d="M216.56,31.52A9,9,0,0,1,213,27.93a10.87,10.87,0,0,1-1.28-5.38,10.59,10.59,0,0,1,1.31-5.34,9.1,9.1,0,0,1,3.58-3.57,11,11,0,0,1,10.15,0,9.08,9.08,0,0,1,3.59,3.55,10.67,10.67,0,0,1,1.31,5.36,10.48,10.48,0,0,1-1.35,5.38,9.39,9.39,0,0,1-3.65,3.59,10.5,10.5,0,0,1-5.12,1.26A10.11,10.11,0,0,1,216.56,31.52Zm8.34-2.45a6.3,6.3,0,0,0,2.5-2.49,8,8,0,0,0,.95-4,8.11,8.11,0,0,0-.93-4A6.19,6.19,0,0,0,225,16.05a6.84,6.84,0,0,0-3.28-.81,6.77,6.77,0,0,0-3.29.81,6,6,0,0,0-2.4,2.47,8.45,8.45,0,0,0-.89,4,8.54,8.54,0,0,0,.88,4.07,5.83,5.83,0,0,0,2.35,2.46,6.56,6.56,0,0,0,3.24.82A7,7,0,0,0,224.9,29.07Z"
                      transform="translate(-0.84 -5.06)"
                    />
                    <path
                      d="M241.76,13.3a7.67,7.67,0,0,1,3.91-.94v3.39h-.87q-5.51,0-5.5,6V32.45H236V12.72h3.28v3.21A6.25,6.25,0,0,1,241.76,13.3Z"
                      transform="translate(-0.84 -5.06)"
                    />
                    <path
                      d="M256.79,29.43l6.12-16.71h3.49l-7.74,19.73h-3.81l-7.74-19.73h3.53Z"
                      transform="translate(-0.84 -5.06)"
                    />
                    <path
                      d="M269.75,8.87a2.16,2.16,0,0,1-.65-1.58,2.18,2.18,0,0,1,.65-1.59,2.16,2.16,0,0,1,1.59-.64,2.05,2.05,0,0,1,1.53.64,2.2,2.2,0,0,1,.63,1.59,2.18,2.18,0,0,1-.63,1.58,2.06,2.06,0,0,1-1.53.65A2.16,2.16,0,0,1,269.75,8.87Zm3.17,3.85V32.45h-3.28V12.72Z"
                      transform="translate(-0.84 -5.06)"
                    />
                    <path
                      d="M293.94,14.54a8.38,8.38,0,0,1,2.24,6.28V32.45h-3.24V21.29a6.37,6.37,0,0,0-1.48-4.52,5.28,5.28,0,0,0-4-1.56,5.39,5.39,0,0,0-4.12,1.62,6.61,6.61,0,0,0-1.53,4.71V32.45H278.5V12.72h3.28v2.81a6.45,6.45,0,0,1,2.64-2.34,8.26,8.26,0,0,1,3.69-.83A8,8,0,0,1,293.94,14.54Z"
                      transform="translate(-0.84 -5.06)"
                    />
                    <path d="M315.22,17.87v2.77H300.86V17.87Z" transform="translate(-0.84 -5.06)" />
                    <path
                      d="M327.12,13.3a7.66,7.66,0,0,1,3.9-.94v3.39h-.86q-5.51,0-5.51,6V32.45h-3.27V12.72h3.27v3.21A6.28,6.28,0,0,1,327.12,13.3Z"
                      transform="translate(-0.84 -5.06)"
                    />
                    <path
                      d="M338.38,31.52a9,9,0,0,1-3.52-3.59,10.87,10.87,0,0,1-1.28-5.38,10.7,10.7,0,0,1,1.31-5.34,9.1,9.1,0,0,1,3.58-3.57,11,11,0,0,1,10.16,0,9.12,9.12,0,0,1,3.58,3.55,10.67,10.67,0,0,1,1.31,5.36,10.48,10.48,0,0,1-1.35,5.38,9.39,9.39,0,0,1-3.65,3.59,10.49,10.49,0,0,1-5.11,1.26A10.13,10.13,0,0,1,338.38,31.52Zm8.34-2.45a6.3,6.3,0,0,0,2.5-2.49,7.88,7.88,0,0,0,.95-4,8.11,8.11,0,0,0-.93-4,6.19,6.19,0,0,0-2.45-2.47,7.07,7.07,0,0,0-6.57,0,5.94,5.94,0,0,0-2.39,2.47,8.33,8.33,0,0,0-.9,4,8.54,8.54,0,0,0,.88,4.07,5.79,5.79,0,0,0,2.36,2.46,6.5,6.5,0,0,0,3.24.82A7,7,0,0,0,346.72,29.07Z"
                      transform="translate(-0.84 -5.06)"
                    />
                    <path
                      d="M360.76,32a6.81,6.81,0,0,1-2.85-2.16,5.75,5.75,0,0,1-1.15-3.18h3.39a3.3,3.3,0,0,0,1.38,2.41,5.32,5.32,0,0,0,3.26.93,4.74,4.74,0,0,0,2.95-.82,2.54,2.54,0,0,0,1.08-2.09,2.05,2.05,0,0,0-1.15-1.93,16.42,16.42,0,0,0-3.56-1.24,25.28,25.28,0,0,1-3.59-1.17A6.4,6.4,0,0,1,358.15,21a4.61,4.61,0,0,1-1-3.08,4.65,4.65,0,0,1,.9-2.77,6.09,6.09,0,0,1,2.55-2,9.27,9.27,0,0,1,3.78-.74,8.06,8.06,0,0,1,5.3,1.66,6,6,0,0,1,2.16,4.53h-3.28a3.39,3.39,0,0,0-1.24-2.48,5.47,5.47,0,0,0-5.85-.18,2.32,2.32,0,0,0-1.05,2,2.16,2.16,0,0,0,.63,1.6,4.57,4.57,0,0,0,1.59,1,27.53,27.53,0,0,0,2.64.84,29.82,29.82,0,0,1,3.46,1.14A6.08,6.08,0,0,1,371,24.17a4.57,4.57,0,0,1,1,2.92,5,5,0,0,1-.9,2.91,6,6,0,0,1-2.54,2,9.11,9.11,0,0,1-3.76.74A10.12,10.12,0,0,1,360.76,32Z"
                      transform="translate(-0.84 -5.06)"
                    />
                    <path
                      d="M394.53,23.81H378.76A6.19,6.19,0,0,0,385.17,30a6.06,6.06,0,0,0,3.54-1,5.21,5.21,0,0,0,2-2.64h3.53A8.49,8.49,0,0,1,391.07,31a9.54,9.54,0,0,1-5.9,1.79,10,10,0,0,1-5-1.26,9,9,0,0,1-3.47-3.59,11.08,11.08,0,0,1-1.26-5.38,11.3,11.3,0,0,1,1.22-5.36,8.66,8.66,0,0,1,3.44-3.55,10.25,10.25,0,0,1,5.1-1.24,9.94,9.94,0,0,1,5,1.22A8.5,8.5,0,0,1,393.46,17a9.93,9.93,0,0,1,1.17,4.84A19.59,19.59,0,0,1,394.53,23.81Zm-4.11-5.88a5.39,5.39,0,0,0-2.25-2.06,7.14,7.14,0,0,0-3.15-.7,6.08,6.08,0,0,0-4.23,1.59,6.47,6.47,0,0,0-2,4.39h12.46A6,6,0,0,0,390.42,17.93Z"
                      transform="translate(-0.84 -5.06)"
                    />
                    <path
                      d="M398.41,32a2.28,2.28,0,0,1,0-3.17A2.18,2.18,0,0,1,400,28.2a2.06,2.06,0,0,1,1.53.65,2.31,2.31,0,0,1,0,3.17,2.06,2.06,0,0,1-1.53.65A2.18,2.18,0,0,1,398.41,32Z"
                      transform="translate(-0.84 -5.06)"
                    />
                    <path
                      d="M406.51,17.21a8.88,8.88,0,0,1,3.37-3.55,9.31,9.31,0,0,1,4.81-1.26A8.9,8.9,0,0,1,419,13.46a7.64,7.64,0,0,1,3,2.79V5.81h3.32V32.45H422V28.74a7.63,7.63,0,0,1-2.88,2.9,8.53,8.53,0,0,1-4.46,1.14,9,9,0,0,1-8.14-4.94,11.37,11.37,0,0,1-1.22-5.32A11.13,11.13,0,0,1,406.51,17.21Zm14.58,1.45a6.31,6.31,0,0,0-2.43-2.53,6.84,6.84,0,0,0-6.71,0,6.18,6.18,0,0,0-2.41,2.52,8,8,0,0,0-.9,3.89,8.21,8.21,0,0,0,.9,3.94A6.32,6.32,0,0,0,412,29a6.51,6.51,0,0,0,3.35.89,6.6,6.6,0,0,0,3.36-.89,6.3,6.3,0,0,0,2.43-2.55,8.15,8.15,0,0,0,.9-3.91A8,8,0,0,0,421.09,18.66Z"
                      transform="translate(-0.84 -5.06)"
                    />
                    <path
                      d="M448.74,23.81H433A6.19,6.19,0,0,0,439.38,30a6.07,6.07,0,0,0,3.55-1,5.19,5.19,0,0,0,2-2.64h3.53A8.48,8.48,0,0,1,445.29,31a9.59,9.59,0,0,1-5.91,1.79,10,10,0,0,1-5-1.26,8.92,8.92,0,0,1-3.48-3.59,11.08,11.08,0,0,1-1.25-5.38,11.19,11.19,0,0,1,1.22-5.36,8.6,8.6,0,0,1,3.44-3.55,10.18,10.18,0,0,1,5.09-1.24,10,10,0,0,1,5,1.22A8.5,8.5,0,0,1,447.68,17a9.93,9.93,0,0,1,1.17,4.84A19.69,19.69,0,0,1,448.74,23.81Zm-4.1-5.88a5.51,5.51,0,0,0-2.25-2.06,7.14,7.14,0,0,0-3.15-.7A6,6,0,0,0,435,16.76a6.43,6.43,0,0,0-2,4.39h12.45A6,6,0,0,0,444.64,17.93Z"
                      transform="translate(-0.84 -5.06)"
                    />
                  </svg>
                </span>
              </p>
            </div>
          </div>
          <p className={styles["copyright"]}>{new Date().getFullYear()}&nbsp;&copy; Corvin Rose</p>
        </div>
      </div>
      <div className={styles["footer-accent"]}></div>
    </footer>
  );
}
