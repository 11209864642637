import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    document
      .getElementById("root")
      ?.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
  }, [pathname]);

  return null;
}
