import React, { useEffect, useState } from "react";
import Header from "../components/header/header";
import { Link } from "react-router-dom";
import { BlogService } from "../service/blog-service";
import { BlogMetadata } from "../model/blog-metadata";
import If from "../components/misc/if/if";
import BlogGrid from "../components/blog/blog-grid/blog-grid";
import BlogSkeleton from "../components/blog/blog-skeleton/blog-skeleton";
import BlogItem from "../components/blog/blog-item/blog-item";

export default function Main() {
  const BLOG_PREVIEW_COUNT = 3;
  const blogService = new BlogService();
  const [metadata, setMetadata] = useState([] as BlogMetadata[]);

  useEffect(() => {
    blogService
      .getNAmountOfBlogPosts(BLOG_PREVIEW_COUNT)
      .subscribe({ next: (data) => setMetadata(data), error: console.error });
  }, []);

  return (
    <div>
      <Header />
      <section id="content-start" className="section-content">
        <div className="container">
          <h1>Corvin Rose</h1>
          <p>
            Schön, dass du auf meiner Seite gelandet bist! Ich bin Corvin,
            {` ${new Date(Date.now() - 1030406400000).getFullYear() - 1970}`} Jahre alt und Student
            der Angewandten Informatik. Mit meinem Wissen möchte ich anderen Leuten auf dieser Seite
            weiterhelfen. Hier bekommst du Einblicke in meine Coding-Projekte, mein Studium und
            alles rund um das Thema Informatik. Wenn du mehr erfahren willst, dann klicke{" "}
            <Link to="/about-me">hier</Link>
          </p>
        </div>
      </section>
      <section className="section-content" style={{ paddingTop: "10em" }}>
        <div className="container">
          <h2 style={{ marginBottom: 0 }}>Blog</h2>
          <h3>Neuigkeiten</h3>
          <br />
          <If condition={metadata.length === 0}>
            <BlogGrid>
              {Array(BLOG_PREVIEW_COUNT)
                .fill({})
                .map((_, i) => (
                  <BlogSkeleton key={i}></BlogSkeleton>
                ))}
            </BlogGrid>
          </If>
          <If condition={metadata.length !== 0}>
            <BlogGrid>
              {metadata.map((blogInfo: BlogMetadata, i) => (
                <BlogItem
                  key={i}
                  id={blogInfo.link}
                  title={blogInfo.title}
                  publishingDate={new Date(Date.parse(blogInfo.date))}
                  description={blogInfo.description}
                  img={`/blog/${blogInfo.path}/header.jpg`}
                />
              ))}
            </BlogGrid>
          </If>
        </div>
      </section>
    </div>
  );
}
