import { useEffect, useState } from "react";
import styles from "./menu.module.css";
import CookieService from "../../service/cookie-service";
import If from "../misc/if/if";
import { Link, useLocation } from "react-router-dom";

export default function Menu() {
  const url = useLocation().pathname;

  const [menuVisible, setMenuVisible] = useState(false);
  const [themeSelectVisible, setThemeSelectVisible] = useState(false);

  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(document.body.scrollTop);
    };
    handleScroll();

    document.body.addEventListener("scroll", handleScroll);
    return () => {
      document.body.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const setTheme = (sender: any) => {
    const themeDisplay = document.getElementById("theme-display");
    if (themeDisplay) themeDisplay.setAttribute("theme", sender.getAttribute("theme"));
    CookieService.setCookie("fixed-theme", sender.getAttribute("theme"), 365);
    window.location.reload();
  };

  return (
    <div>
      <div className={styles["menu-wrapper"]}>
        <div
          id="menu"
          className={`${styles["menu"]} ${menuVisible ? styles["menu-expanded"] : ""} ${
            scrollY > 10 ? styles["menu-shadow"] : ""
          }`}
        >
          <div className={`container disable-select`}>
            <Link to="/">
              <div className={styles["logo"]}>
                <svg viewBox="0 0 2049 2049" style={{ display: "block" }}>
                  <path
                    className={styles["logo-segment"]}
                    d="M1547.77,699.74l-139.54,43.13a473.21,473.21,0,0,0-103.13-103l43.16-139.64a47.47,47.47,0,0,0-27.2-57.89l-95.82-39.69a47.47,47.47,0,0,0-60.16,21.7L1096.9,553.52a475.8,475.8,0,0,0-145.75.11L882.92,424.35a47.47,47.47,0,0,0-60.16-21.7l-95.82,39.69a47.47,47.47,0,0,0-27.2,57.89l43.13,139.54a473.21,473.21,0,0,0-103,103.13L500.23,699.74a47.47,47.47,0,0,0-57.89,27.2l-39.69,95.82a47.47,47.47,0,0,0,21.7,60.16L553.52,951.1a475.8,475.8,0,0,0,.11,145.75l-129.28,68.23a47.47,47.47,0,0,0-21.7,60.16l39.69,95.82a47.47,47.47,0,0,0,57.89,27.2l139.54-43.13a473.21,473.21,0,0,0,103.13,103l-43.16,139.64a47.47,47.47,0,0,0,27.2,57.89l95.82,39.69a47.47,47.47,0,0,0,60.16-21.7l68.18-129.17a475.8,475.8,0,0,0,145.75-.11l68.23,129.28a47.47,47.47,0,0,0,60.16,21.7l95.82-39.69a47.47,47.47,0,0,0,27.2-57.89l-43.13-139.54a473.21,473.21,0,0,0,103-103.13l139.64,43.16a47.47,47.47,0,0,0,57.89-27.2l39.69-95.82a47.47,47.47,0,0,0-21.7-60.16l-129.17-68.18a475.8,475.8,0,0,0-.11-145.75l129.28-68.23a47.47,47.47,0,0,0,21.7-60.16l-39.69-95.82A47.47,47.47,0,0,0,1547.77,699.74ZM1147.5,1322.15c-164.67,68.21-353.44-10-421.65-174.65s10-353.44,174.65-421.65,353.44,10,421.65,174.65S1312.16,1253.94,1147.5,1322.15Z"
                    transform="translate(0.5 0.5)"
                  />
                  <circle className={styles["logo-segment"]} cx="1024.5" cy="1024.5" r="181.5" />
                  <polyline
                    className={styles["logo-segment"]}
                    points="549 1053 17.5 1289.03 17.5 2031.5 760.95 2031.5 997 1500"
                  />
                  <polyline
                    className={styles["logo-segment"]}
                    points="1500 996 2031.5 759.97 2031.5 17.5 1288.05 17.5 1052 549"
                  />
                </svg>
              </div>
            </Link>

            <nav>
              <div className={styles["hamburger-btn"]} onClick={() => setMenuVisible(!menuVisible)}>
                <svg
                  className={styles["hamburger-icon"]}
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 64 64"
                >
                  <rect
                    className={`${styles["bar"]} ${styles["bar-1"]}`}
                    y="6"
                    width="64"
                    height="6"
                  />
                  <rect
                    className={`${styles["bar"]} ${styles["bar-2"]}`}
                    y="29"
                    width="64"
                    height="6"
                  />
                  <rect
                    className={`${styles["bar"]} ${styles["bar-3"]}`}
                    y="52"
                    width="64"
                    height="6"
                  />
                </svg>
              </div>
              <div className={styles["navbar-links"]}>
                <ul>
                  <li>
                    <Link to="/" onClick={() => setMenuVisible(false)}>
                      Start
                    </Link>
                  </li>
                  <li>
                    <Link to="/blog" onClick={() => setMenuVisible(false)}>
                      Blog
                    </Link>
                  </li>
                  <li>
                    <Link to="/about-me" onClick={() => setMenuVisible(false)}>
                      Über mich
                    </Link>
                  </li>
                  <li>
                    <Link to="/extras" onClick={() => setMenuVisible(false)}>
                      Extras
                    </Link>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </div>

      <If condition={url !== "/"}>
        <div className={styles["menu-space"]}></div>
      </If>
    </div>
  );
}
