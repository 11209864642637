import styles from "./contact.module.css";
import { useRef, useState } from "react";
import { CaptchaCharacter, CaptchaGenerator } from "./captcha-generator";
import useDidMount from "../../service/hooks/use-did-mount";
import { ContactService } from "../../service/contact-service";

export default function Contact() {
  const EMAIL_REGEX =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const contactService = new ContactService();

  const boundingBox = useRef({} as HTMLDivElement);
  const canvas = useRef({} as HTMLCanvasElement);
  const nameInput = useRef({} as HTMLInputElement);
  const senderInput = useRef({} as HTMLInputElement);
  const messageInput = useRef({} as HTMLTextAreaElement);
  const captchaInput = useRef({} as HTMLInputElement);

  const [messageLength, setMessageLength] = useState(0);
  const [captchaCharacters, setCaptchaCharacters] = useState([] as CaptchaCharacter[]);

  const removeError = (e: any) => {
    e.target.classList.remove(styles["error"]);
  };

  const messageChange = (e: any) => {
    let value = e.target.value.length;
    setMessageLength(value);
  };

  const generateCaptcha = () => {
    const chars = CaptchaGenerator.generateCaptcha();
    setCaptchaCharacters(chars);
    renderCaptcha(chars);
  };
  const renderCaptcha = (chars?: CaptchaCharacter[]) => {
    canvas.current.width = boundingBox.current.clientWidth;
    canvas.current.height = boundingBox.current.clientHeight;
    CaptchaGenerator.renderCaptcha(canvas.current, chars ?? captchaCharacters);
  };

  const submit = () => {
    if (nameInput.current.value.trim().length == 0) {
      nameInput.current.classList.add(styles["error"]);
      return;
    }
    if (
      senderInput.current.value.trim().length == 0 ||
      senderInput.current.value.match(EMAIL_REGEX) == null
    ) {
      senderInput.current.classList.add(styles["error"]);
      return;
    }
    if (messageInput.current.value.trim().length < 50) {
      messageInput.current.classList.add(styles["error"]);
      return;
    }
    const captchaValue = captchaCharacters.map((v) => v.value).join("");
    if (
      captchaInput.current.value.trim().length === 0 ||
      captchaInput.current.value !== captchaValue
    ) {
      captchaInput.current.classList.add(styles["error"]);
      return;
    }

    contactService.sendMessage({
      name: nameInput.current.value,
      email: senderInput.current.value,
      message: messageInput.current.value,
    });
  };

  useDidMount(() => {
    generateCaptcha();
    window.addEventListener("resize", () => {
      renderCaptcha();
    });
  });

  return (
    <div className="container">
      <h1>Kontakt</h1>
      <form className={styles["contact"]}>
        <div className={styles["input-row-2"]}>
          {/*TODO: Required * */}
          <input
            ref={nameInput}
            type="text"
            name="name"
            placeholder="Dein Name"
            onKeyUp={(e) => removeError(e)}
          />
          <input
            ref={senderInput}
            type="text"
            name="sender"
            placeholder="E-Mail"
            onKeyUp={(e) => removeError(e)}
          />
        </div>
        <div className={styles["text-container"]}>
          <textarea
            ref={messageInput}
            name="message"
            cols={30}
            rows={10}
            placeholder={"Nachricht (min 50 Zeichen)"}
            onKeyUp={(e) => {
              removeError(e);
              messageChange(e);
            }}
          ></textarea>
          <span
            className={styles["char-display"]}
            style={{ color: messageLength < 50 ? "#f00" : "#b2b2b2" }}
          >
            {messageLength}
          </span>
        </div>
        <div className={styles["input-row-2"]}>
          <div style={{ display: "flex", gap: 10, width: "100%" }}>
            <div ref={boundingBox} className={`${styles["captcha-boundingbox"]} neu-container-si1`}>
              <canvas ref={canvas} className={styles["recaptcha"]}></canvas>
            </div>
            <button className={styles["refresh"]} type="button" onClick={generateCaptcha}>
              <svg
                fill="#000000"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 32 32"
                width="32px"
                height="32px"
              >
                <path d="M 16 4 C 10.886719 4 6.617188 7.160156 4.875 11.625 L 6.71875 12.375 C 8.175781 8.640625 11.710938 6 16 6 C 19.242188 6 22.132813 7.589844 23.9375 10 L 20 10 L 20 12 L 27 12 L 27 5 L 25 5 L 25 8.09375 C 22.808594 5.582031 19.570313 4 16 4 Z M 25.28125 19.625 C 23.824219 23.359375 20.289063 26 16 26 C 12.722656 26 9.84375 24.386719 8.03125 22 L 12 22 L 12 20 L 5 20 L 5 27 L 7 27 L 7 23.90625 C 9.1875 26.386719 12.394531 28 16 28 C 21.113281 28 25.382813 24.839844 27.125 20.375 Z" />
              </svg>
            </button>
          </div>
          <input
            ref={captchaInput}
            type="text"
            placeholder="Recaptcha"
            onKeyUp={(e) => removeError(e)}
          />
        </div>
        <div>
          <button id="submit-btn" type="button" className={styles["submit"]} onClick={submit}>
            Submit
          </button>
        </div>
      </form>
    </div>
  );
}
