import "./globals.css";
import Menu from "./components/menu/menu";
import Footer from "./components/footer/footer";
import { Outlet } from "react-router-dom";
import ScrollToTop from "./components/misc/scroll-to-top/scroll-to-top";

export default function RootLayout() {
  return (
    <div>
      {/* html contents */}
      <ScrollToTop />
      <Menu />
      <Outlet />
      <Footer />
    </div>
  );
}
