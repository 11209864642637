import styles from "./skill-display.module.css";

export default function SkillDisplay({
  value = "beginner",
  label = "Skill",
}: {
  value?: "beginner" | "intermediate" | "expert";
  label?: string;
}) {
  const mapValue = (val: "beginner" | "intermediate" | "expert") => {
    switch (val) {
      case "beginner":
        return 1;
      case "intermediate":
        return 2;
      case "expert":
        return 3;
    }
  };

  return (
    <div className={styles["skill-display"]}>
      <span style={{ width: "max-content" }}>{label}</span>
      <div className={`${styles["skill-bar"]} neu-progbar`}>
        <div
          className={`${styles["skill-fill"]} ${
            mapValue(value) >= 1 ? styles["s1"] : ""
          } neu-progbar-fill`}
        ></div>
        <div
          className={`${styles["skill-fill"]} ${
            mapValue(value) >= 2 ? styles["s2"] : ""
          } neu-progbar-fill`}
        ></div>
        <div
          className={`${styles["skill-fill"]} ${styles["bar-end"]} ${
            mapValue(value) >= 3 ? styles["s3"] : ""
          } neu-progbar-fill`}
        ></div>
      </div>
    </div>
  );
}
