import styles from "./blog-skeleton.module.css";
import Spacer from "../../misc/spacer/spacer";
import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export default function BlogSkeleton() {
  return (
    <div className={`${styles["blog-entry"]} ${styles["blog-skeleton"]}`}>
      <SkeletonTheme baseColor="#F3F4F6" highlightColor="#fafafa">
        <Skeleton height={160} className={styles["img-skeleton"]} />
        <div className={styles["blog-entry-details"]}>
          <Skeleton height={24} />
          <Spacer height={"0.2em"} />
          <Skeleton height={16} />
          <Spacer height={"0.5em"} />
          <Skeleton count={3} />
        </div>
      </SkeletonTheme>
    </div>
  );
}
