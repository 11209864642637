import styles from "./header.module.css";
import { useEffect, useState } from "react";

export default function Header() {
  const [logoLock, setLogoLock] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLogoLock(false);
    }, 3000);
  });

  const animateLogo = () => {
    const logo = document.getElementById("logo-gear");
    if (!logo || logoLock) return;
    logo.style.opacity = "1";
    logo.style.transform = "scale(1) translate(-50%, -50%)";
    logo.style.animation = `${styles["spin"]} 1.5s linear infinite`;
    logo.style.animationPlayState = "running";
  };

  const stopAnimateLogo = () => {
    const logo = document.getElementById("logo-gear");
    if (!logo || logoLock) return;
    setTimeout(() => {
      logo.style.animationPlayState = "paused";
    }, 0.25);
  };

  return (
    <div className={styles.header}>
      <div className={styles["logo-hero"]}>
        <svg
          className={styles["logo-outline"]}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 2049 2049"
        >
          <path
            className={`${styles["logo-segment"]} ${styles["out-draw-2"]}`}
            d="M1547.77,699.74l-139.54,43.13a473.21,473.21,0,0,0-103.13-103l43.16-139.64a47.47,47.47,0,0,0-27.2-57.89l-95.82-39.69a47.47,47.47,0,0,0-60.16,21.7L1096.9,553.52a475.8,475.8,0,0,0-145.75.11L882.92,424.35a47.47,47.47,0,0,0-60.16-21.7l-95.82,39.69a47.47,47.47,0,0,0-27.2,57.89l43.13,139.54a473.21,473.21,0,0,0-103,103.13L500.23,699.74a47.47,47.47,0,0,0-57.89,27.2l-39.69,95.82a47.47,47.47,0,0,0,21.7,60.16L553.52,951.1a475.8,475.8,0,0,0,.11,145.75l-129.28,68.23a47.47,47.47,0,0,0-21.7,60.16l39.69,95.82a47.47,47.47,0,0,0,57.89,27.2l139.54-43.13a473.21,473.21,0,0,0,103.13,103l-43.16,139.64a47.47,47.47,0,0,0,27.2,57.89l95.82,39.69a47.47,47.47,0,0,0,60.16-21.7l68.18-129.17a475.8,475.8,0,0,0,145.75-.11l68.23,129.28a47.47,47.47,0,0,0,60.16,21.7l95.82-39.69a47.47,47.47,0,0,0,27.2-57.89l-43.13-139.54a473.21,473.21,0,0,0,103-103.13l139.64,43.16a47.47,47.47,0,0,0,57.89-27.2l39.69-95.82a47.47,47.47,0,0,0-21.7-60.16l-129.17-68.18a475.8,475.8,0,0,0-.11-145.75l129.28-68.23a47.47,47.47,0,0,0,21.7-60.16l-39.69-95.82A47.47,47.47,0,0,0,1547.77,699.74ZM1147.5,1322.15c-164.67,68.21-353.44-10-421.65-174.65s10-353.44,174.65-421.65,353.44,10,421.65,174.65S1312.16,1253.94,1147.5,1322.15Z"
            transform="translate(0.5 0.5)"
          />
          <circle
            className={`${styles["logo-segment"]} ${styles["out-draw-1"]}`}
            cx="1024.5"
            cy="1024.5"
            r="181.5"
          />
          <polyline
            className={`${styles["logo-segment"]} ${styles["out-draw-3"]}`}
            points="549 1053 17.5 1289.03 17.5 2031.5 760.95 2031.5 997 1500"
          />
          <polyline
            className={`${styles["logo-segment"]} ${styles["out-draw-4"]}`}
            points="1500 996 2031.5 759.97 2031.5 17.5 1288.05 17.5 1052 549"
          />
        </svg>
        <img
          className={`${styles["logo-wings"]} disable-select disable-drag`}
          src="/img/logo_wings.svg"
          alt=""
        />
        <img
          id="logo-gear"
          className={`${styles["logo-gear"]} disable-select disable-drag`}
          src="/img/logo_gear_dark.svg"
          alt=""
        />
        <div
          id="logo-cta"
          className={styles["logo-cta"]}
          onMouseEnter={animateLogo}
          onMouseLeave={stopAnimateLogo}
        ></div>
      </div>
      <div className={`${styles["code-window"]} glass`}>
        <div className={styles["window-linenums"]}></div>
        <div className={styles["window-bar"]}>
          <p></p>
          <p></p>
          <p></p>
        </div>
        <div className={styles.typewriter}>
          <div style={{ width: "min-content" }}>
            <div className={`${styles.ln} ${styles.ln1}`}>
              <p>Hey, ich bin Corvin&nbsp;</p>
            </div>
          </div>
          <div style={{ width: "min-content" }}>
            <div className={`${styles.ln} ${styles.ln2}`}>
              <p>willkommen auf meiner&nbsp;</p>
            </div>
          </div>
          <div style={{ width: "min-content" }}>
            <div className={`${styles.ln} ${styles.ln3}`}>
              <p>Website&nbsp;</p>
            </div>
          </div>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
        </div>
      </div>
    </div>
  );
}
