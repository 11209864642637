export enum SpaceType {
  Div,
  Paragraph,
  Table,
}

export default function Spacer({
  height = "2em",
  type = SpaceType.Div,
}: {
  height: string;
  type?: SpaceType;
}) {
  const style = { display: "block", width: "100%", height: height };

  switch (type) {
    case SpaceType.Div:
      return <div className={"space"} style={style}></div>;
    case SpaceType.Paragraph:
      return <span className={"space"} style={style}></span>;
    case SpaceType.Table:
      return <tr className={"space"} style={style}></tr>;
  }
}
