import { Link } from "react-router-dom";
import styles from "../../components/footer/footer.module.css";

export default function PrivacyEn() {
  return (
    <div className="container">
      <h1>Privacy policy</h1>
      <h2>1. Data protection at a glance</h2>
      <h4>General information</h4>{" "}
      <p>
        The following information provides a simple overview of what happens to your personal data
        when you visit this website. Personal data is any data that can be used to identify you
        personally. Detailed information on the subject of data protection can be found in our data
        protection declaration listed below this text.
      </p>
      <h4>Data collection on this website</h4>{" "}
      <h4>Who is responsible for data collection on this website?</h4>{" "}
      <p>
        Data processing on this website is carried out by the website operator. You can find the
        operator's contact details in the "Information on the controller" section of this privacy
        policy.
      </p>{" "}
      <h4>How do we collect your data?</h4>{" "}
      <p>
        On the one hand, your data is collected when you provide it to us. This may, for example, be
        data that you enter in a contact form.
      </p>{" "}
      <p>
        Other data is collected automatically or with your consent by our IT systems when you visit
        the website. This is primarily technical data (e.g. internet browser, operating system or
        time of page view). This data is collected automatically as soon as you enter this website.
      </p>{" "}
      <h4>What do we use your data for?</h4>{" "}
      <p>
        Some of the data is collected to ensure that the website is provided without errors. Other
        data can be used to analyze your user behavior.
      </p>{" "}
      <h4>Welche Rechte haben Sie bez&uuml;glich Ihrer Daten?</h4>
      <p>
        You have the right to receive information about the origin, recipient and purpose of your
        stored personal data free of charge at any time. You also have the right to request the
        correction or deletion of this data. If you have given your consent to data processing, you
        can revoke this consent at any time for the future. You also have the right to request the
        restriction of the processing of your personal data under certain circumstances.
        Furthermore, you have the right to lodge a complaint with the competent supervisory
        authority.
      </p>{" "}
      <p>
        You can contact us at any time if you have further questions on the subject of data
        protection.
      </p>
      <h4>Analysis tools and tools from third-party providers</h4>{" "}
      <p>
        When you visit this website, your surfing behavior may be statistically evaluated. This is
        mainly done with so-called analysis programs.
      </p>
      <p>
        Detailed information on these analysis programs can be found in the following privacy
        policy.
      </p>
      <h2>2. Hosting</h2>
      <p>We host the content of our website with the following provider:</p>
      <h4>Hetzner</h4>{" "}
      <p>
        The provider is Hetzner Online GmbH, Industriestr. 25, 91710 Gunzenhausen (hereinafter
        referred to as Hetzner).
      </p>{" "}
      <p>
        Details can be found in Hetzner's privacy policy:{" "}
        <a
          href="https://www.hetzner.com/de/rechtliches/datenschutz"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.hetzner.com/de/rechtliches/datenschutz
        </a>
        .
      </p>{" "}
      <p>
        The use of Hetzner is based on Art. 6 para. 1 lit. f GDPR. We have a legitimate interest in
        displaying our website as reliably as possible. If a corresponding consent has been
        requested, the processing is carried out exclusively on the basis of Art. 6 para. 1 lit. a
        GDPR and § 25 para. 1 TDDDG, insofar as the consent includes the storage of cookies or
        access to information in the user's terminal device (e.g. device fingerprinting) within the
        meaning of the TDDDG. Consent can be revoked at any time.
      </p>
      <h4>Order processing</h4>{" "}
      <p>
        We have concluded a data processing agreement (DPA) for the use of the above-mentioned
        service. This is a contract prescribed by data protection law, which ensures that it
        processes the personal data of our website visitors only in accordance with our instructions
        and in compliance with the GDPR.
      </p>
      <h2>3. General notes and mandatory information</h2>
      <h4>Data protection</h4>{" "}
      <p>
        The operators of these pages take the protection of your personal data very seriously. We
        treat your personal data confidentially and in accordance with the statutory data protection
        regulations and this privacy policy.
      </p>{" "}
      <p>
        When you use this website, various personal data is collected. Personal data is data that
        can be used to identify you personally. This privacy policy explains what data we collect
        and what we use it for. It also explains how and for what purpose this is done.
      </p>{" "}
      <p>
        We would like to point out that data transmission over the Internet (e.g. when communicating
        by email) may be subject to security vulnerabilities. Complete protection of data against
        access by third parties is not possible.
      </p>
      <h4>Note on the responsible authority</h4>{" "}
      <p>You can find the data processing controller on the legal notice page:</p>{" "}
      <p>
        <Link className={styles["link"]} to="/imprint">
          Imprint
        </Link>
      </p>
      <p>
        The responsible party is the natural or legal person who alone or jointly with others
        determines the purposes and means of the processing of personal data (e.g. names, e-mail
        addresses, etc.).
      </p>
      <h4>Storage duration</h4>{" "}
      <p>
        Unless a more specific storage period has been specified in this privacy policy, your
        personal data will remain with us until the purpose for data processing no longer applies.
        If you assert a justified request for deletion or revoke your consent to data processing,
        your data will be deleted unless we have other legally permissible reasons for storing your
        personal data (e.g. retention periods under tax or commercial law); in the latter case, the
        deletion will take place after these reasons no longer apply.
      </p>
      <h4>General information on the legal basis for data processing on this website</h4>{" "}
      <p>
        If you have consented to data processing, we process your personal data on the basis of Art.
        6 para. 1 lit. a GDPR or Art. 9 para. 2 lit. a GDPR, insofar as special categories of data
        are processed in accordance with Art. 9 para. 1 GDPR. In the event of express consent to the
        transfer of personal data to third countries, data processing is also carried out on the
        basis of Art. 49 para. 1 lit. a GDPR. If you have consented to the storage of cookies or
        access to information in your end device (e.g. via device fingerprinting), the data
        processing is also carried out on the basis of Section 25 (1) TDDDG. Consent can be revoked
        at any time. If your data is required to fulfill the contract or to carry out
        pre-contractual measures, we process your data on the basis of Art. 6 para. 1 lit. b GDPR.
        Furthermore, we process your data if this is necessary to fulfill a legal obligation on the
        basis of Art. 6 para. 1 lit. c GDPR. Data processing may also be carried out on the basis of
        our legitimate interest in accordance with Art. 6 para. 1 lit. f GDPR. Information on the
        relevant legal bases in each individual case is provided in the following paragraphs of this
        privacy policy.
      </p>
      <h4>Recipients of personal data</h4>{" "}
      <p>
        Im Rahmen unserer Geschäftstätigkeit arbeiten wir mit verschiedenen externen Stellen
        zusammen. Dabei ist teilweise auch eine Übermittlung von personenbezogenen Daten an diese
        externen Stellen erforderlich. Wir geben personenbezogene Daten nur dann an externe Stellen
        weiter, wenn dies im Rahmen einer Vertragserfüllung erforderlich ist, wenn wir gesetzlich
        hierzu verpflichtet sind (z. B. Weitergabe von Daten an Steuerbehörden), wenn wir ein
        berechtigtes Interesse nach Art. 6 Abs. 1 lit. f DSGVO an der Weitergabe haben oder wenn
        eine sonstige Rechtsgrundlage die Datenweitergabe erlaubt. Beim Einsatz von
        Auftragsverarbeitern geben wir personenbezogene Daten unserer Kunden nur auf Grundlage eines
        gültigen Vertrags über Auftragsverarbeitung weiter. Im Falle einer gemeinsamen Verarbeitung
        wird ein Vertrag über gemeinsame Verarbeitung geschlossen.
      </p>
      <h4>Revocation of your consent to data processing</h4>{" "}
      <p>
        Many data processing operations are only possible with your express consent. You can
        withdraw your consent at any time. The legality of the data processing carried out until the
        revocation remains unaffected by the revocation.
      </p>
      <h4>
        Right to object to the collection of data in special cases and to direct marketing (Art. 21
        GDPR)
      </h4>{" "}
      <p>
        IF THE DATA PROCESSING IS BASED ON ART. 6 ABS. 1 LIT. E OR F GDPR, YOU HAVE THE RIGHT TO
        OBJECT TO THE PROCESSING OF YOUR PERSONAL DATA AT ANY TIME ON GROUNDS RELATING TO YOUR
        PARTICULAR SITUATION; THIS ALSO APPLIES TO PROFILING BASED ON THESE PROVISIONS. THE
        RESPECTIVE LEGAL BASIS ON WHICH PROCESSING IS BASED CAN BE FOUND IN THIS PRIVACY POLICY. IF
        YOU OBJECT, WE WILL NO LONGER PROCESS YOUR PERSONAL DATA CONCERNED UNLESS WE CAN DEMONSTRATE
        COMPELLING LEGITIMATE GROUNDS FOR THE PROCESSING WHICH OVERRIDE YOUR INTERESTS, RIGHTS AND
        FREEDOMS OR THE PROCESSING SERVES THE ESTABLISHMENT, EXERCISE OR DEFENSE OF LEGAL CLAIMS
        (OBJECTION PURSUANT TO ART. 21 PARA. 1 GDPR).
      </p>{" "}
      <p>
        IF YOUR PERSONAL DATA ARE PROCESSED FOR THE PURPOSE OF DIRECT MARKETING, YOU HAVE THE RIGHT
        TO OBJECT AT ANY TIME TO THE PROCESSING OF PERSONAL DATA CONCERNING YOU FOR THE PURPOSE OF
        SUCH MARKETING; THIS ALSO APPLIES TO PROFILING TO THE EXTENT THAT IT IS RELATED TO SUCH
        DIRECT MARKETING. IF YOU OBJECT, YOUR PERSONAL DATA WILL SUBSEQUENTLY NO LONGER BE USED FOR
        THE PURPOSE OF DIRECT MARKETING (OBJECTION PURSUANT TO ART. 21 PARA. 2 GDPR).
      </p>
      <h4>Right to lodge a complaint with the competent supervisory authority</h4>{" "}
      <p>
        In the event of breaches of the GDPR, data subjects have the right to lodge a complaint with
        a supervisory authority, in particular in the Member State of their habitual residence,
        place of work or place of the alleged infringement. The right to lodge a complaint is
        without prejudice to any other administrative or judicial remedies.
      </p>
      <h4>Right to data portability</h4>{" "}
      <p>
        You have the right to have data that we process automatically on the basis of your consent
        or in fulfillment of a contract handed over to you or to a third party in a common,
        machine-readable format. If you request the direct transfer of the data to another
        controller, this will only be done insofar as it is technically feasible.
      </p>
      <h4>Information, correction and deletion</h4>{" "}
      <p>
        Within the framework of the applicable legal provisions, you have the right to free
        information about your stored personal data, its origin and recipients and the purpose of
        the data processing and, if necessary, a right to correction or deletion of this data at any
        time. You can contact us at any time if you have further questions on the subject of
        personal data.
      </p>
      <h4>Right to restriction of processing</h4>{" "}
      <p>
        You have the right to request the restriction of the processing of your personal data. You
        can contact us at any time to do this. The right to restriction of processing exists in the
        following cases:
      </p>{" "}
      <ul>
        {" "}
        <li>
          If you dispute the accuracy of your personal data stored by us, we generally need time to
          check this. For the duration of the review, you have the right to request the restriction
          of the processing of your personal data.
        </li>{" "}
        <li>
          If the processing of your personal data was/is carried out unlawfully, you can request the
          restriction of data processing instead of erasure.
        </li>{" "}
        <li>
          If we no longer need your personal data, but you need it for the exercise, defense or
          assertion of legal claims, you have the right to request the restriction of the processing
          of your personal data instead of its erasure.
        </li>{" "}
        <li>
          If you have lodged an objection in accordance with Art. 21 para. 1 GDPR, a balance must be
          struck between your interests and ours. As long as it has not yet been determined whose
          interests prevail, you have the right to request the restriction of the processing of your
          personal data.
        </li>{" "}
      </ul>
      <p>
        If you have restricted the processing of your personal data, this data - apart from its
        storage - may only be processed with your consent or for the establishment, exercise or
        defense of legal claims or for the protection of the rights of another natural or legal
        person or for reasons of important public interest of the European Union or of a Member
        State.
      </p>
      <h4>SSL and TLS encryption</h4>{" "}
      <p>
        This site uses SSL or TLS encryption for security reasons and to protect the transmission of
        confidential content, such as orders or inquiries that you send to us as the site operator.
        You can recognize an encrypted connection by the fact that the address line of the browser
        changes from "http://" to "https://" and by the lock symbol in your browser line.
      </p>{" "}
      <p>
        If SSL or TLS encryption is activated, the data you transmit to us cannot be read by third
        parties.
      </p>
      <h2>4. Data collection on this website</h2>
      <h4>Cookies</h4>{" "}
      <p>
        Our Internet pages use so-called "cookies". Cookies are small data packets and do not cause
        any damage to your end device. They are stored on your device either temporarily for the
        duration of a session (session cookies) or permanently (permanent cookies). Session cookies
        are automatically deleted at the end of your visit. Permanent cookies remain stored on your
        end device until you delete them yourself or they are automatically deleted by your web
        browser.
      </p>{" "}
      <p>
        Cookies may originate from us (first-party cookies) or from third-party companies (so-called
        third-party cookies). Third-party cookies enable the integration of certain services from
        third-party companies within websites (e.g. cookies for processing payment services).
      </p>{" "}
      <p>
        Cookies have various functions. Many cookies are technically necessary, as certain website
        functions would not work without them (e.g. the shopping cart function or the display of
        videos). Other cookies can be used to evaluate user behavior or for advertising purposes.
      </p>{" "}
      <p>
        Cookies that are required to carry out the electronic communication process, to provide
        certain functions that you have requested (e.g. for the shopping cart function) or to
        optimize the website (e.g. cookies to measure the web audience) (necessary cookies) are
        stored on the basis of Art. 6 para. 1 lit. f GDPR, unless another legal basis is specified.
        The website operator has a legitimate interest in the storage of necessary cookies for the
        technically error-free and optimized provision of its services. If consent to the storage of
        cookies and comparable recognition technologies has been requested, the processing is
        carried out exclusively on the basis of this consent (Art. 6 para. 1 lit. a GDPR and § 25
        para. 1 TDDDG); the consent can be revoked at any time.
      </p>
      <p>
        You can set your browser so that you are informed about the setting of cookies and only
        allow cookies in individual cases, exclude the acceptance of cookies for certain cases or in
        general and activate the automatic deletion of cookies when closing the browser. If cookies
        are deactivated, the functionality of this website may be restricted.
      </p>
      <p>
        You can find out which cookies and services are used on this website in this privacy policy.
      </p>
      <h4>Contact form</h4>{" "}
      <p>
        If you send us inquiries via the contact form, your details from the inquiry form, including
        the contact details you provide there, will be stored by us for the purpose of processing
        the inquiry and in the event of follow-up questions. We will not pass on this data without
        your consent.
      </p>{" "}
      <p>
        This data is processed on the basis of Art. 6 para. 1 lit. b GDPR if your request is related
        to the performance of a contract or is necessary for the implementation of pre-contractual
        measures. In all other cases, the processing is based on our legitimate interest in the
        effective processing of the inquiries addressed to us (Art. 6 para. 1 lit. f GDPR) or on
        your consent (Art. 6 para. 1 lit. a GDPR) if this has been requested; consent can be revoked
        at any time.
      </p>{" "}
      <p>
        We will retain the data you provide on the contact form until you request its deletion,
        revoke your consent for its storage, or the purpose for its storage no longer pertains (e.g.
        after fulfilling your request). Mandatory statutory provisions - in particular retention
        periods - remain unaffected.
      </p>
      <h4>Request by e-mail, telephone or fax</h4>{" "}
      <p>
        If you contact us by e-mail, telephone or fax, we will store and process your inquiry,
        including all personal data (name, inquiry), for the purpose of processing your request. We
        will not pass on this data without your consent.
      </p>{" "}
      <p>
        This data is processed on the basis of Art. 6 para. 1 lit. b GDPR if your request is related
        to the performance of a contract or is necessary for the implementation of pre-contractual
        measures. In all other cases, the processing is based on our legitimate interest in the
        effective processing of the inquiries addressed to us (Art. 6 para. 1 lit. f GDPR) or on
        your consent (Art. 6 para. 1 lit. a GDPR) if this has been requested; consent can be revoked
        at any time.
      </p>{" "}
      <p>
        The data you send to us via contact requests will remain with us until you ask us to delete
        it, revoke your consent to storage or the purpose for data storage no longer applies (e.g.
        after your request has been processed). Mandatory statutory provisions - in particular
        statutory retention periods - remain unaffected.
      </p>
      <p>
        Source: <a href="https://www.e-recht24.de">https://www.e-recht24.de</a>
      </p>
    </div>
  );
}
