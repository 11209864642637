import styles from "./timeline.module.css";
import Spacer from "../../misc/spacer/spacer";

export class TimelineItem {
  year: number;
  img: string;
  label: string;
  constructor(year: number, img: string, label: string) {
    this.year = year;
    this.img = img;
    this.label = label;
  }
}

export default function Timeline({ items }: { items: TimelineItem[] }) {
  return (
    <div className={styles["timeline-wrapper"]}>
      <div
        className={styles["timeline"]}
        style={{ gridTemplateColumns: `repeat(${items.length}, 1fr)` }}
      >
        {items.map((item) => (
          <div className={styles["timeline-section"]} key={item.label}>
            <div className={styles["timeline-section-space"]}>
              <div className={styles["timeline-section-label"]}>
                <img src={item.img} alt="Timeline section icon" />
                <p>{item.label}</p>
              </div>
              <div className={styles["timeline-connection"]}></div>
              <Spacer height={"12px"} />
            </div>
            <div className={styles["timeline-section-arrow"]}>
              <span className={styles["timeline-year"]}>{item.year}</span>
            </div>
            <div className={styles["timeline-section-space"]}></div>
          </div>
        ))}
      </div>
      <div className={styles["timeline-bar"]}></div>
    </div>
  );
}
