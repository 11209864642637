import React, { useEffect, useState } from "react";
import BlogGrid from "../../components/blog/blog-grid/blog-grid";
import { BlogMetadata } from "../../model/blog-metadata";
import BlogItem from "../../components/blog/blog-item/blog-item";
import If from "../../components/misc/if/if";
import BlogSkeleton from "../../components/blog/blog-skeleton/blog-skeleton";
import { BlogService } from "../../service/blog-service";

export default function Blog() {
  const blogService = new BlogService();
  const [metadata, setMetadata] = useState([] as BlogMetadata[]);

  useEffect(() => {
    blogService
      .getAllBlogPosts()
      .subscribe({ next: (data) => setMetadata(data), error: console.error });
  }, []);

  return (
    <div className="container">
      <h1>BLOG</h1>
      <If condition={metadata.length === 0}>
        <BlogGrid>
          {Array(6)
            .fill({})
            .map((_, i) => (
              <BlogSkeleton key={i}></BlogSkeleton>
            ))}
        </BlogGrid>
      </If>
      <If condition={metadata.length !== 0}>
        <BlogGrid>
          {metadata.map((blogInfo: BlogMetadata, i) => (
            <BlogItem
              key={i}
              id={blogInfo.link}
              title={blogInfo.title}
              publishingDate={new Date(Date.parse(blogInfo.date))}
              description={blogInfo.description}
              img={`/blog/${blogInfo.path}/header.jpg`}
            />
          ))}
        </BlogGrid>
      </If>
    </div>
  );
}
