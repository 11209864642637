import { Observable } from "rxjs";
import { HttpClient } from "./http-client";
import { ContactMessage } from "../model/contact-message";

export class ContactService {
  private readonly BASE_URL: string;
  private http: HttpClient;

  constructor() {
    this.BASE_URL = process.env.REACT_APP_BACKEND_URL ?? "";
    this.http = new HttpClient();
  }

  sendMessage(message: ContactMessage): Observable<void> {
    return this.http.post(`${this.BASE_URL}/contact/send-message`, message);
  }
}
