import React from "react";
import ReactDOM from "react-dom/client";
import "./globals.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import RootLayout from "./layout";
import Main from "./pages/main";
import Blog from "./pages/blog/blog";
import AboutMe from "./pages/about-me/about-me";
import NotFound from "./pages/not-found";
import Extras from "./pages/extras/extras";
import Imprint from "./pages/legal/imprint";
import Privacy from "./pages/legal/privacy";
import BlogPost from "./pages/blog/blog-post";
import Contact from "./pages/contact/contact";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<RootLayout />}>
          <Route index element={<Main />} />
          <Route path="blog">
            <Route index element={<Blog />} />
            <Route path=":id" element={<BlogPost />} />
          </Route>
          <Route path="about-me" element={<AboutMe />} />
          <Route path="extras" element={<Extras />} />
          <Route path="privacy" element={<Privacy />} />
          <Route path="imprint" element={<Imprint />} />
          <Route path="contact" element={<Contact />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
