const supportedLanguages = [
  { lang: "de", code: "de-DE" },
  { lang: "en", code: "en-US" },
];

export class LanguageService {
  static getBrowserLanguage(): string {
    return (
      supportedLanguages
        .filter((v) => v.lang === navigator.language)
        .map((v) => v.code)
        .pop() ?? "en-US"
    );
  }
}
