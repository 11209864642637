import styles from "./blog-item.module.css";
import React from "react";
import Spacer from "../../misc/spacer/spacer";
import { Link } from "react-router-dom";
import { DateService } from "../../../service/date-service";

export default function BlogItem({
  id,
  title,
  img,
  publishingDate,
  description,
}: {
  id: string;
  title: string;
  img?: string;
  publishingDate: Date;
  description: string;
}) {
  return (
    <div className={`${styles["blog-entry"]} glass`}>
      <Link className={styles["blog-link"]} to={`/blog/${id}`}>
        <img
          src={img ?? "/img/blank.gif"}
          width="320"
          height="160"
          loading={"lazy"}
          alt="Blog Image"
        />
        <div className={styles["blog-entry-details"]}>
          <h2>{title}</h2>
          <p>{DateService.formatDate(publishingDate)}</p>
          <Spacer height={"0.5em"} />
          <p>{description}</p>
        </div>
      </Link>
    </div>
  );
}
