import styles from "./code-display.module.css";
import { PrismAsyncLight as SyntaxHighlighter } from "react-syntax-highlighter";
import { prism } from "react-syntax-highlighter/dist/esm/styles/prism";

interface Line {
  text: string;
  indent: number;
}

export default function CodeDisplay({ lang, children = "" }: { lang: string; children?: string }) {
  return (
    <div style={{ position: "relative" }}>
      <div className={`${styles["lang"]} ${styles[lang]}`}>
        <SyntaxHighlighter language={lang} className={`${styles["code-display"]}`} style={prism}>
          {children
            .split("\n")
            .filter((v, i) => v.trim().length != 0 || i != 0)
            .map((v) => {
              return {
                text: v,
                indent: (v.match(/^ */) ?? [""])[0].length,
              } as Line;
            })
            .reduce((a, b) => {
              if (!a) {
                return b;
              }
              return {
                text: `${a.text}\n${b.text.substring(a.indent, b.text.length)}`,
                indent: a.indent,
              } as Line;
            })
            .text.trim()}
        </SyntaxHighlighter>
      </div>
    </div>
  );
}

/*
Languages:

1c abnf accesslog actionscript ada angelscript apache applescript arcade arduino armasm
asciidoc aspectj autohotkey autoit avrasm awk axapta bash basic bnf brainfuck c-like c cal
capnproto ceylon clean clojure-repl clojure cmake coffeescript coq cos cpp crmsh crystal
csharp csp css d dart delphi diff django dns dockerfile dos dsconfig dts dust ebnf elixir elm
erb erlang-repl erlang excel fix flix fortran fsharp gams gauss gcode gherkin glsl gml go golo
gradle groovy haml handlebars haskell haxe hsp htmlbars http hy inform7 ini irpf90 isbl java
javascript jboss-cli json julia-repl julia kotlin lasso latex ldif leaf less lisp
livecodeserver livescript llvm lsl lua makefile markdown mathematica matlab maxima mel mercury
mipsasm mizar mojolicious monkey moonscript n1ql nginx nim nix node-repl nsis objectivec ocaml
openscad oxygene parser3 perl pf pgsql php-template php plaintext pony powershell processing
profile prolog properties protobuf puppet purebasic python-repl python q qml r reasonml rib
roboconf routeros rsl ruby ruleslanguage rust sas scala scheme scilab scss shell smali
smalltalk sml sqf sql sql_more stan stata step21 stylus subunit swift taggerscript tap tcl
thrift tp twig typescript vala vbnet vbscript-html vbscript verilog vhdl vim x86asm xl xml
xquery yaml zephir
*/
