import { EffectCallback, useEffect, useRef } from "react";

export default function useDidMount(callback: EffectCallback) {
  const didMount = useRef(false);

  useEffect(() => {
    if (callback && !didMount.current) {
      didMount.current = true;
      callback();
    }
  });
}
