export interface CaptchaCharacter {
  value: string;
  font: string;
  rotation: number;
}

export class CaptchaGenerator {
  private static readonly DARK: boolean = false;
  private static readonly FONTS: string[] = ["cursive", "sans-serif", "serif", "monospace"];

  public static generateCaptcha(): CaptchaCharacter[] {
    let value = btoa(String(Math.random() * 10000000000));
    value = value.substring(0, 5 + Math.random() * 3);
    return value.split("").map((v) => {
      return {
        value: v,
        font: this.FONTS[Math.trunc(Math.random() * this.FONTS.length)],
        rotation: -30 + Math.trunc(Math.random() * 50),
      } as CaptchaCharacter;
    });
  }

  public static renderCaptcha(canvas: HTMLCanvasElement, captchaValue: CaptchaCharacter[]) {
    const ctx: CanvasRenderingContext2D = canvas.getContext("2d") as CanvasRenderingContext2D;
    const w = canvas.width;
    const h = canvas.height;
    ctx.clearRect(0, 0, w, h);
    let chars = captchaValue;
    for (let i = 0; i < chars.length; i++) {
      let x = w / 2 - (chars.length * 20) / 2 + 20 * i;
      let y = h / 2 + 6;
      ctx.save();
      ctx.translate(x, y);
      ctx.rotate((chars[i].rotation * Math.PI) / 180);
      ctx.font = `20px ${chars[i].font}`;
      ctx.fillStyle = (this.DARK ? "#fff" : "#000") + (i % 2 == 1 ? "5" : "");
      ctx.fillText(chars[i].value, 0, 0);
      ctx.restore();
    }
  }
}
