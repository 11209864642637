import { LanguageService } from "./language-service";

export class DateService {
  private static readonly OPTIONS: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  public static formatDate(date: Date) {
    return date.toLocaleDateString(LanguageService.getBrowserLanguage(), this.OPTIONS);
  }
}
