import { BlogMetadata } from "../model/blog-metadata";
import { Observable } from "rxjs";
import { HttpClient } from "./http-client";
import { BlogContent } from "../model/blog-content";

export class BlogService {
  private readonly BASE_URL: string;
  private http: HttpClient;

  constructor() {
    this.BASE_URL = process.env.REACT_APP_BACKEND_URL ?? "";
    this.http = new HttpClient();
  }

  getAllBlogPosts(): Observable<BlogMetadata[]> {
    return this.http.get<BlogMetadata[]>(`${this.BASE_URL}/blog/metadata`);
  }

  getNAmountOfBlogPosts(n: number): Observable<BlogMetadata[]> {
    return this.http.get<BlogMetadata[]>(`${this.BASE_URL}/blog/metadata/${n}`);
  }

  getBlogContentWithId(id: string): Observable<BlogContent> {
    return this.http.get<BlogContent>(`${this.BASE_URL}/blog/content/${id}`);
  }
}
