import If from "../../components/misc/if/if";
import { LanguageService } from "../../service/language-service";
import PrivacyDe from "./privacy-de";
import PrivacyEn from "./privacy-en";

export default function Privacy() {
  const lang = LanguageService.getBrowserLanguage();

  return (
    <div>
      <If condition={lang === "de-DE"}>
        <PrivacyDe />
      </If>
      <If condition={lang !== "de-DE"}>
        <PrivacyEn />
      </If>
    </div>
  );
}
