import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { BlogService } from "../../service/blog-service";
import { BlogContent } from "../../model/blog-content";
import Markdown, { Components } from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeKatex from "rehype-katex";
import remarkMath from "remark-math";
import "katex/dist/katex.min.css";
import CodeDisplay from "../../components/misc/code-display/code-display";
import { DateService } from "../../service/date-service";
import styles from "./blog-post.module.css";

export default function BlogPost() {
  const { id } = useParams();
  const blogService = new BlogService();
  const [postContent, setPostContent] = useState({} as BlogContent);

  const markdownComponents: Components = {
    code(props) {
      const { children, className, node, ...rest } = props;
      const match = /language-(\w+)/.exec(className || "");
      return match ? (
        <CodeDisplay lang={match[1]} children={String(children).replace(/\n$/, "")} />
      ) : (
        <code {...rest} className={className}>
          {children}
        </code>
      );
    },
    table(props) {
      const { children, className, node, ...rest } = props;
      return (
        <div style={{ overflowX: "auto" }}>
          <table className={"border"}>{children}</table>
        </div>
      );
    },
    img(props) {
      const { children, className, node, ...img } = props;
      const match = /\.svg$/.exec(img.src || "");
      return match ? (
        <svg>
          <use href={`${img.src}#Ebene_1`}></use>
        </svg>
      ) : (
        <img loading={"lazy"} src={img.src} alt={img.alt} />
      );
    },
  };

  useEffect(() => {
    if (id) {
      blogService
        .getBlogContentWithId(id)
        .subscribe({ next: (data: BlogContent) => setPostContent(data), error: console.error });
    }
  }, []);

  return (
    <div className="container">
      <h1>{postContent.title?.length !== 0 ? postContent.title : "No title"}</h1>
      <img
        className={styles["blog-img"]}
        src={`/blog/${postContent.path}/header.jpg`}
        alt={"Blog Banner"}
      />
      <p className={styles["date"]}>{DateService.formatDate(new Date(postContent.date))}</p>
      <br />
      <Markdown
        remarkPlugins={[remarkGfm, remarkMath]}
        rehypePlugins={[rehypeKatex]}
        components={markdownComponents}
      >
        {postContent.htmlContent}
      </Markdown>
    </div>
  );
}
