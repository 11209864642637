import styles from "./about-me.module.css";
import SkillDisplay from "../../components/about-me/skill-display/skill-display";
import Spacer from "../../components/misc/spacer/spacer";
import Timeline, { TimelineItem } from "../../components/about-me/timeline/timeline";

export default function AboutMe() {
  return (
    <div className="container">
      <h1>ÜBER MICH</h1>
      <div className={styles["portrait-grid"]}>
        <img
          className={`${styles["portrait"]} disable-drag disable-select`}
          src="/img/about/portrait.jpg"
          alt="Portrait"
        />
        <div>
          <p>
            Bevor ich näher über meinen Studiengang berichte, möchte ich zu aller Erst über meine
            Person erzählen. Abgesehen von der Informatik interessieren mich vor allem Themengebiete
            der Naturwissenschaften und der Mathematik. Produktivität und Zeitmanagement hat für
            mich große Priorität, so strukturiere ich also meinen Alltag und meine Aufzeichnungen
            für die Uni bzw. Projekte für die Arbeit nach diesen Prinzipien. Allgemein kann man
            sagen, dass ich sehr gerne neue Sachverstände versuche zu verstehen und dabei neue
            Techniken und Herangehensweisen ausprobiere.
          </p>
        </div>
      </div>
      <Spacer height={"2em"} />

      <h2>Kenntnisse und Fähigkeiten</h2>
      <div className={styles["skill-grid"]}>
        <div className={styles["skill-column"]}>
          <SkillDisplay label={"Java"} value={"expert"} />
          <SkillDisplay label={"Spring"} value={"intermediate"} />
          <SkillDisplay label={"JS / TS"} value={"intermediate"} />
          <SkillDisplay label={"CSS"} value={"expert"} />
          <SkillDisplay label={"Angular"} value={"intermediate"} />
        </div>
        <div className={styles["skill-column"]}>
          <SkillDisplay label={"PHP"} value={"intermediate"} />
          <SkillDisplay label={"C#"} value={"intermediate"} />
          <SkillDisplay label={"Unity"} value={"intermediate"} />
          <SkillDisplay label={"React"} value={"intermediate"} />
          <SkillDisplay label={"Python"} value={"intermediate"} />
        </div>
      </div>
      <Spacer height={"2em"} />
      <p>
        Die Grafik oben soll zeigen, wie gut ich mit verschiedenen Frameworks oder Tools umgehen
        kann. Um als außenstehender die Grafik einfach interpretieren zu können, habe ich eine
        Fähigkeit in drei Stufen unterteilt. Blau steht dabei für das Beherrschen der Grundlagen. Es
        soll aussagen, dass ich mich grundlegend mit der Technologie auskenne. Lila steht für
        fortgeschrittene Kenntnisse in diesem Bereich. Ich kann also komplexe Aufgaben lösen und
        bewältigen. Rot steht für Expertenwissen, ich kenne also alle kleinen Tricks, die es in
        diesem Gebiet gibt und kann selbst sehr schwierige Anforderungen erfüllen.
      </p>
      <Spacer height={"2em"} />

      <h2>Warum Informatik?</h2>
      <p>
        Angewandte Informatik. So wird mein Studiengang betitelt aber was genau bedeutet das und
        warum habe ich mich für diesen Studiengang entschieden? Das möchte ich in den folgenden
        Abschnitten näher erläutern.
      </p>
      <p>
        Mathematik liegt mir wirklich gut und macht weitestgehend auch Spaß, dadurch kann ich viele
        Module des Studiums relativ einfach bewältigen. Ich möchte damit nicht sagen, dass es
        einfach ist aber es ist definitiv ein Punkt der für das Studium spricht. Abseits von den
        Mathematik-Modulen gibt es noch andere Module, die sehr mathebezogen sind, wie z.B.
        Algorithmen und Datenstrukturen oder Grundlagen der Informatik. Aber auch in Themen, wie
        künstliche Intelligenzen oder maschinelles Lernen, spielt Mathematik eine große Rolle. Das
        logische und abstrakte Denken motiviert mich in diesen Punkten sehr.
      </p>
      <p>
        Was mich wirklich antreibt ist es Menschen zu helfen. Mit Software kann ich so Millionen
        Menschen auf einmal helfen. Das können Programme sein, die einfache Alltagsprobleme lösen
        bis hin zu komplexen KI-Systemen, die helfen gesünder zu Leben. Es gibt einfach sehr viele
        Möglichkeiten zu helfen.
      </p>
      <p>
        Probleme treten täglich im Informatikgeschäft auf. Das interessante daran ist, dass man sich
        selbst Lösungswege ausdenken kann bzw. die Art der Lösung nicht vorgeschrieben ist. Man
        könnte sagen es gibt unendlich viele Möglichkeiten ein Problem zu lösen und das macht es
        interessant, weil Kollegen andere Vorgehensweisen haben, als man selbst. Und so kann man
        auch voneinander lernen. Das zeigt auch, dass es niemals langweilig werden kann, weil man
        jeden Tag etwas Neues zu lernen hat.
      </p>
      <p>
        Mit dem Wissen aus dem Studium habe ich die Möglichkeit selbst eigene Tools zu entwickeln,
        die den Alltag erleichtern, oder mir technische Gadgets auszudenken und zu programmieren.
        Man kann also erkennen, dass es wirklich viele Einsatzgebiete für die Informatik gibt, die
        nicht sofort auf der Hand liegen.
      </p>
      <Spacer height={"2em"} />

      <h2>Meilensteine</h2>
      <p>
        Dieser Zeitstrahl gibt einen Überblick über meine Programmiererfahrungen. Ich begann als
        neugieriger Anfänger und habe im Laufe der Jahre eine breite Palette an
        Programmierfähigkeiten erworben und mich in verschiedenen Bereichen der Software-Entwicklung
        engagiert. Der Zeitstrahl zeigt meine wichtigsten Meilensteine und Projekte in meiner
        Programmier-Karriere und wie sich meine Fähigkeiten und Interessen im Laufe der Zeit
        entwickelt haben.
      </p>
      <Spacer height={"2em"} />
      <Timeline
        items={[
          // new TimelineItem(2015, "/img/about/vb-net.svg", "VB .Net Anwendungen"),
          // new TimelineItem(2016, "/img/about/cs-net.svg", "C# .Net Anwendungen"),
          new TimelineItem(2017, "/img/about/unity.svg", "Spielentwicklung als Hobby"),
          new TimelineItem(2019, "/img/about/playstore.svg", "Erstes Spiel im PlayStore"),
          new TimelineItem(2020, "/img/about/htw.svg", "Immatrikulation an der HTW-Berlin"),
          new TimelineItem(2021, "/img/about/js.svg", "Einstieg in die Webentwicklung"),
          new TimelineItem(2022, "/img/about/adesso.svg", "Beginn meiner Karriere bei Adesso SE"),
          new TimelineItem(2024, "/img/about/htw.svg", "Bachelorabschluss"),
        ]}
      />
    </div>
  );
}
